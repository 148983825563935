import dayjs from 'dayjs'
import { IntlShape } from 'react-intl'
import {
  object as yupObject,
  number as yupNumber,
  string as yupString,
  mixed as yupMixed,
  lazy as yupLazy,
} from 'yup'

import { keys as messageKeys } from 'utils/validation/messages'

import { buildDateOfBirth } from './utils'

const isDateOfBirthInFuture = (dateOfBirth: string): boolean => {
  const now = dayjs()
  return dayjs(dateOfBirth).isAfter(now)
}

export const getValidationSchemas = (
  intl: IntlShape,
  showStateSelector: boolean,
) => {
  const minMessage = (min: number) => ({
    id: messageKeys.MIN_VALUE,
    values: { min_value: min },
  })

  const maxMessage = (max: number) => ({
    id: messageKeys.MAX_VALUE,
    values: { max_value: max },
  })

  const daySchema = yupNumber()
    .nullable()
    .required({ id: messageKeys.REQUIRED })
    .typeError({ id: messageKeys.NUMBER })
    .min(1, minMessage(1))
    .max(31, maxMessage(31))

  const monthSchema = yupNumber()
    .nullable()
    .required({ id: messageKeys.REQUIRED })
    .typeError({ id: messageKeys.NUMBER })
    .min(1, minMessage(1))
    .max(12, maxMessage(12))

  const yearSchema = yupNumber()
    .nullable()
    .required({ id: messageKeys.REQUIRED })
    .typeError({ id: messageKeys.NUMBER })
    .min(1900, minMessage(1900))

  const isDob = (day: number, month: number, year: number) =>
    daySchema.isValidSync(day) &&
    monthSchema.isValidSync(month) &&
    yearSchema.isValidSync(year)

  return yupObject().shape({
    dateOfBirth: yupObject({
      day: daySchema,
      month: monthSchema,
      year: yearSchema,
    }).test('check if future date', { id: 'DOB_IN_THE_FUTURE' }, value => {
      const { day, month, year } = value

      if (!isDob(day, month, year)) {
        return true
      }

      const dob = buildDateOfBirth(day, month, year)

      return !isDateOfBirthInFuture(dob)
    }),
    selectedState: yupLazy(() =>
      showStateSelector
        ? yupObject({
          label: yupString(),
          value: yupString().required({
            id: 'STATE_AGE_GATE_SUBMIT_ERROR_STATE_MISSING',
          }),
        })
        : yupMixed().notRequired(),
    ),
  })
}

import React from 'react'

import { getMessages } from 'components/ageGate/utils'
import { LocalizedLink } from 'components/common/LocalizedLink'
import { TranslatedMessage } from 'components/common/TranslatedMessage'
import { useTranslations } from 'utils/translations/useTranslations'

export const CanadaWarrantySupportText = (): JSX.Element => {
  const messageKeys = getMessages('regular')

  const createLink = (label: string, url: string) => {
    const { translateMessage } = useTranslations()

    const linkText = translateMessage(label)
    const linkUrl = translateMessage(url)

    return (
      <LocalizedLink href={linkUrl}>
        {linkText}
      </LocalizedLink>
    )
  }

  return (
    <p>
      <TranslatedMessage
        id={messageKeys.WARRANTY_LINKS}
        values={{
          accessory_warranty_link: createLink(
            messageKeys.ACCESSORY_WARRANTY_LINK_TEXT,
            messageKeys.ACCESSORY_WARRANTY_LINK_URL,
          ),
          device_warranty_link: createLink(
            messageKeys.DEVICE_WARRANTY_LINK_TEXT,
            messageKeys.DEVICE_WARRANTY_LINK_URL,
          ),
          pod_replacement_link: createLink(
            messageKeys.POD_REPLACEMENT_LINK_TEXT,
            messageKeys.POD_REPLACEMENT_LINK_URL,
          ),
        }}
      />
    </p>
  )
}

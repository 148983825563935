import React from 'react'

import { Button, TextInput } from '@juullabs/react-components'
import { useFormik } from 'formik'
import { useIntl } from 'react-intl'
import colorStyles from 'styles/common/color.module.scss'

import { BlockedStateWarningText } from 'components/ageGate/components/BlockedStateWarningText'
import { StateSelector } from 'components/ageGate/components/StateSelector'
import { TranslatedTextMessage } from 'components/ageGate/components/TranslatedTextMessage'
import { TranslatedMessage } from 'components/common/TranslatedMessage'

import commonStyles from '../common.module.scss'

import styles from './CanadaAgeGate.module.scss'
import { getValidationSchemas } from './CanadaBodyForm.schema'
import { buildDateOfBirth } from './utils'

type TranslatedMessageErrorProps = {
  id: string
  values: Record<string, unknown>
}

const getError = (
  formik,
  fieldName: string,
): TranslatedMessageErrorProps | undefined => {
  const { touched, error } = formik.getFieldMeta(fieldName)
  return touched && error ? error : undefined
}

type State = {
  label: string
  value: string
}

type DateOfBirth = {
  day: number | null
  month: number | null
  year: number | null
}

type FormData = {
  dateOfBirth: DateOfBirth
  selectedState: State
}

const getInitialValues = (state: State): FormData => ({
  dateOfBirth: {
    day: null,
    month: null,
    year: null,
  },
  selectedState: state,
})

export const CanadaBodyForm =
  ({ ageGateUIPolicy, config, onSubmit }) => {
    const intl = useIntl()

    const geoLocatedState = ageGateUIPolicy.getTheGeoState()

    const blockedGeoLocatedState =
      geoLocatedState &&
      geoLocatedState.value &&
      ageGateUIPolicy.blockedState(geoLocatedState)

    const showStateSelector = config.selector && !blockedGeoLocatedState

    const formik = useFormik({
      enableReinitialize: true,
      initialValues: getInitialValues(ageGateUIPolicy.getInitialGeoState()),
      onSubmit: values => {
        onSubmit(
          values.selectedState,
          buildDateOfBirth(
            values.dateOfBirth.day,
            values.dateOfBirth.month,
            values.dateOfBirth.year,
          ),
        )
      },
      validationSchema: getValidationSchemas(intl, showStateSelector),
    })

    const showBlockedStateWarning =
      ageGateUIPolicy.shouldShowBlockedStateWarning(
        formik.values.selectedState,
      ) && !ageGateUIPolicy.isWarrantyAgeGate

    const ctaTextKey = ageGateUIPolicy.shouldShowRouteToLocatorButton(
      formik.values.selectedState,
    ) && !ageGateUIPolicy.isWarrantyAgeGate
      ? 'DOB_AGE_GATE_CTA_STORE_LOCATOR'
      : 'DOB_AGE_GATE_CTA_TEXT_SUBMIT'

    return (
      <form onSubmit={formik.handleSubmit}>
        <div className={commonStyles.subsection}>
          {showStateSelector && (
            <>
              <StateSelector
                isCanada={true}
                selector={config.selector}
                selectedState={formik.values.selectedState}
                callback={val => formik.setFieldValue('selectedState', val)}
              />
              {getError(formik, 'selectedState.value') && (
                <div className={colorStyles.red}>
                  <TranslatedTextMessage
                    id={getError(formik, 'selectedState.value').id}
                  />
                </div>
              )}
            </>
          )}

          {showBlockedStateWarning && (
            <BlockedStateWarningText
              stateLabel={formik.values.selectedState.label}
              messageKey='BLOCKED_STATE_WARNING_FULLSCREEN'
            />
          )}
        </div>

        <div className={commonStyles.subsection}>
          <TranslatedTextMessage id={'DOB_INPUT_COPY'} />
          <div className={styles.dobFields}>
            <TextInput
              key='day'
              name='dateOfBirth.day'
              labelText={<TranslatedTextMessage id={'PLACEHOLDER_DAY'} />}
              maxLength={2}
              pattern='\d*'
              wrapperClass={styles.dobField}
              errorText={
                getError(formik, 'dateOfBirth.day') && (
                  <TranslatedMessage {...getError(formik, 'dateOfBirth.day')} />
                )
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.dateOfBirth.day || ''}
            />
            <TextInput
              key='month'
              name='dateOfBirth.month'
              labelText={<TranslatedTextMessage id={'PLACEHOLDER_MONTH'} />}
              maxLength={2}
              pattern='\d*'
              wrapperClass={styles.dobField}
              errorText={
                getError(formik, 'dateOfBirth.month') && (
                  <TranslatedMessage
                    {...getError(formik, 'dateOfBirth.month')}
                  />
                )
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.dateOfBirth.month || ''}
            />
            <TextInput
              key='year'
              name='dateOfBirth.year'
              labelText={<TranslatedTextMessage id={'PLACEHOLDER_YEAR'} />}
              maxLength={4}
              pattern='\d*'
              wrapperClass={styles.dobYearField}
              errorText={
                getError(formik, 'dateOfBirth.year') && (
                  <TranslatedMessage
                    {...getError(formik, 'dateOfBirth.year')}
                  />
                )
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.dateOfBirth.year || ''}
            />
          </div>

          {
            (formik.errors?.dateOfBirth as TranslatedMessageErrorProps)?.id === 'DOB_IN_THE_FUTURE'
            && (
              <div className={colorStyles.red}>
                <TranslatedTextMessage id={'DOB_IN_THE_FUTURE'} />
              </div>
            )
          }
        </div>

        <div className={commonStyles.subsection}>
          <TranslatedTextMessage id={'DOB_CONSENT_COPY'} />
        </div>

        <div className={commonStyles.subsection}>
          <Button
            type='submit'
            id='age-gate-confirmation'
            className={styles.button}
          >
            <TranslatedTextMessage id={ctaTextKey} />
          </Button>
        </div>
      </form>
    )
  }

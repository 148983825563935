import React, { FC } from 'react'

import {
  AgeGateUIPolicy,
  AgeGateConfig,
} from '@viewlio/types/src/AgeGate'

import { SplitLayoutWithLogo } from 'components/common/SplitLayoutWithLogo'

import { CanadaBody } from './CanadaBody'
import { CanadaFooter } from './CanadaFooter'

export type Props = {
  ageGateUIPolicy: AgeGateUIPolicy
  config: AgeGateConfig
  onAgreeButtonClick(stateCode: string): void
  onRouteToLocatorButtonClick(stateCode: string): void
}

export const CanadaAgeGate: FC<Props> = ({
  config,
  ageGateUIPolicy,
  onRouteToLocatorButtonClick,
  onAgreeButtonClick,
}) => (
  <SplitLayoutWithLogo
    contentBody={(
      <CanadaBody
        config={config.body}
        ageGateUIPolicy={ageGateUIPolicy}
        onRouteToLocatorButtonClick={onRouteToLocatorButtonClick}
        onAgreeButtonClick={onAgreeButtonClick}
      />
    )}
    footer={(
      <CanadaFooter
        config={config.footer} />
    )}
  />
)

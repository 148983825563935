import React, { FC } from 'react'

import { ConfigFooter } from '@viewlio/types/src/AgeGate'

import { LanguageSelector } from 'components/ageGate/components/LanguageSelector'

import { CanadaWarrantySupportText } from './CanadaWarrantySupportText'

type Props = {
  config: ConfigFooter
}

export const CanadaFooter: FC<Props> = ({
  config,
}) => (
  <>
    {config.warrantySupport && <CanadaWarrantySupportText />}
    {
      config.languageSelector?.languages?.length > 1 && (
        <LanguageSelector
          languages={config.languageSelector?.languages}
        />
      )
    }
  </>
)

import React, { useContext, FC } from 'react'

import { ConfigBody, AgeGateUIPolicy , StateListItem } from '@viewlio/types/src/AgeGate'
import dayjs from 'dayjs'

import { AgeGateContext } from 'components/ageGate/contexts/AgeGateContext'
import { TranslatedMessage } from 'components/common/TranslatedMessage'
import { useTranslations } from 'utils/translations/useTranslations'

import commonStyles from '../common.module.scss'

import { CanadaBodyForm } from './CanadaBodyForm'

type Props = {
  ageGateUIPolicy: AgeGateUIPolicy
  config: ConfigBody
  onAgreeButtonClick: (stateCode: string) => void
  onRouteToLocatorButtonClick: (stateCode: string) => void
}

export const CanadaBody: FC<Props> = ({
  config,
  ageGateUIPolicy,
  onRouteToLocatorButtonClick,
  onAgreeButtonClick,
}) => {
  const { translateMessage } = useTranslations()

  const { messageKeys } = useContext(AgeGateContext)
  const underAgeUrl = translateMessage(
    messageKeys['DOB_AGE_GATE_UNDERAGE_URL'],
  )

  const {
    minimumAge,
    minimumAgeOverrides,
    rmaMinimumAge,
    rmaMinimumAgeOverrides,
  } = config

  const minimumAgeRequirement = selectedState => {
    const selectedStateValue = selectedState.value.toLowerCase()
    const minimumAgeOverride = ageGateUIPolicy.isWarrantyAgeGate
      ? rmaMinimumAgeOverrides && rmaMinimumAgeOverrides[selectedStateValue]
      : minimumAgeOverrides && minimumAgeOverrides[selectedStateValue]
    const minAge = ageGateUIPolicy.isWarrantyAgeGate
      ? rmaMinimumAge
      : minimumAge

    return minimumAgeOverride || minAge
  }

  const meetsAgeRequirement = (dateOfBirth, selectedState) => {
    if (!dateOfBirth && !selectedState) return false

    const age = dayjs().diff(dateOfBirth, 'year')
    const minAge = minimumAgeRequirement(selectedState)

    return age >= minAge
  }

  const onSubmit = (selectedState: StateListItem, dateOfBirth: string) => {
    if (!meetsAgeRequirement(dateOfBirth, selectedState)) {
      window.location.assign(underAgeUrl)
    } else if (
      ageGateUIPolicy.shouldShowRouteToLocatorButton(selectedState)
      && !ageGateUIPolicy.isWarrantyAgeGate
    ) {
      onRouteToLocatorButtonClick(selectedState.value)
    } else {
      onAgreeButtonClick(selectedState.value)
    }
  }

  return (
    <div id='age-gate-body-container'>
      <div className={commonStyles.subsection}>
        <strong>
          <TranslatedMessage id={messageKeys.AGE_REQUIREMENT_FULLSCREEN_TEXT} />
        </strong>
      </div>
      <CanadaBodyForm
        config={config}
        ageGateUIPolicy={ageGateUIPolicy}
        onSubmit={onSubmit}
      />
    </div>
  )
}
